import React from 'react';
import {
  SecSubTitle,
  SecTitle,
  InfoMedia,
  Button
} from '../../components';

const AboutSeven = ()=> {
  const year = new Date().getFullYear();
return (
  <div className="background-image" style={{backgroundImage: 'url(images/bg/about-bg-5-1.jpg)'}}>
    <div className="container container-style1">
      <div className="row flex-row-reverse align-items-center gx-70">
        <div className="col-lg-6 col-xl">
          <img src="images/about/ab-7-1.jpg" alt="aboutthumb"/>
        </div>
        <div className="col-lg-6 col-xl-auto">
          <div className="about-box2">
            <SecSubTitle><i className="fas fa-bring-forward"/>Get best It solution {year}</SecSubTitle>
            <SecTitle className="text-capitalize h1">Inspiring Tech Needs for Business</SecTitle>
            <p>Looking for the best IT solution for your business in 2023? Look no further than our company. Our team of experienced professionals is dedicated to providing you with the highest quality IT services and solutions to help your business thrive.</p>
            <div className="row gx-0 align-items-center flex-row-reverse justify-content-end mt-sm-3 pt-sm-3 mb-30 pb-10">
              <div className="col-sm-auto">
                <p className="author-degi">Contact</p>
                <h3 className="h5 author-name">Shyam</h3>
              </div>              
              <div className="col-sm-auto">
                <InfoMedia className="about-call"
                  icon="fas fa-phone-alt"
                  title="Call To Ask Any Queary"
                  info={<a href="tel:+25821562154">+(678) 682 9855</a>}
                />
              </div>
            </div>
            <Button path="/service">Order Service<i className="far fa-long-arrow-right"/></Button>
          </div>
        </div>
      </div>
    </div>
  </div>
)

}

export default AboutSeven;