import React from 'react';
import {FeatureBoxOne} from '../../components/';
import Slider from 'react-slick';

const FeatureOne = ({className})=> {

  const settings = {
    autoplay: true,
    autoplaySpeed: 8000,
    infinite: true,
    arrows: false,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {          
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 992,
        settings: {          
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 767,
        settings: {          
          slidesToShow: 1,
        }
      }
    ]
  };
  
  return (
    <div className={`feature-wrap1 ${className || ''}`}>
      <div className="container">   
        <Slider className="row" {...settings}>
          <FeatureBoxOne 
            title="Development Services"
            text="We offer a comprehensive range of Development Services to help businesses of all sizes develop customized software solutions tailored to their specific needs. Our Development Services include full-stack development, web development, mobile app development, and more."            image="images/icon/fe-1-1.png"
            path="/service-details"
          />
          <FeatureBoxOne 
            title="Cloud Services"
            text="We offer a comprehensive range of Cloud Services to help businesses of all sizes leverage the power of the cloud to increase efficiency, scalability, and security. Our Cloud Services include cloud migration, cloud consulting, cloud infrastructure management, and more."
            image="images/icon/fe-1-2.png"
            path="/service-details"
          />
          <FeatureBoxOne 
            title="Consulting Services"
            text="We offer a comprehensive range of Consulting Services to help businesses of all sizes make informed decisions and drive growth and success. Our Consulting Services include IT consulting, business consulting, management consulting, and more."
            image="images/icon/fe-1-3.png"
            path="/service-details"
          />
        </Slider>
      </div>
    </div>
  );
}

export default FeatureOne;