import React from 'react';
import {
  SecSubTitle,
  SecTitle,
  ProgressOne
} from '../../components';

const AboutTwo = ({...restProps})=> (
  <div {...restProps}>
    <div className="container">
      <div className="row flex-row-reverse">
        <div className="col-lg-5 col-xxl-auto mb-30 pb-20 pb-lg-0">
          <img src="images/skill/skill-1-1.jpg" alt="Skill"/>
        </div>
        <div className="col-lg-7 col-xxl-6 me-xl-auto">
          <SecSubTitle><i className="fas fa-bring-forward"/>The Great Company Skill</SecSubTitle>
          <SecTitle className="h1">Connecting People And Build Technology</SecTitle>
          <p className="mb-4 pb-1">We believe that technology has the power to bring people together and drive meaningful change. That's why we are dedicated to helping businesses and organizations of all sizes connect with their customers and communities through cutting-edge technology solutions.</p>
          <ProgressOne title="Application Security" now={100}/>
          <ProgressOne title="Customer Satisfaction" now={100}/>
          <ProgressOne title="Business Inovation" now={100}/>
        </div>
      </div>
    </div>
  </div>
);

export default AboutTwo;