import React from 'react';
import {
  Button, 
  ImageBoxOne, 
  SecTitle, 
  SecSubTitle, 
  InfoMedia
} from '../../components';

const AboutOne = ({className})=> {
  const year =  new Date().getFullYear();
  return (
  <div className={`position-relative ${className || ''}`}>    
    <div className="about-shape1 d-none d-xl-block">NYADS IT Solutions</div>
    <div className="container z-index-common">        
      <div className="row gx-60">
        <div className="col-lg-6 col-xl-5 mb-50 mb-lg-0">
          <ImageBoxOne imageOne="images/about/ab-1-1.jpg"
            imageTwo="images/about/ab-1-2.jpg"
            video="https://www.dropbox.com/s/b4k0p5swok5l8t2/videoplayback.mp4?dl=1"
          />
        </div>
        <div className="col-lg-6 col-xl-7 align-self-center">
          <SecSubTitle><i className="fas fa-bring-forward"/>Get best It solution {year}</SecSubTitle>
          <SecTitle className="h1">Count on Our Best IT Solutions to Drive Your Business Forward</SecTitle>
          <p className="mb-4 mt-1 pb-3">At our company, we understand that technology plays a vital role in the success of your business. That's why we are dedicated to providing you with the best IT solutions to help you achieve your business goals. Our team of experts has years of experience in the IT industry and can provide you with customized solutions that are tailored to your unique business needs. We offer a wide range of IT services, including full-stack development, cloud migration, DevOps, and Liferay portal development. Our goal is to help you leverage technology to drive growth and success for your business. Trust our best IT solutions for your business and let us help you achieve your full potential today.</p>
          <InfoMedia 
            image="images/icon/tel-1-1.png"
            title="24 HOURS SERVICE AVAILABLE"
            info="Call Us: +(678) 682 9855"
            className="call-media"
          />
          <Button path="/about">About Us<i className="far fa-long-arrow-right"/></Button>
        </div>
      </div>
    </div>    
  </div>
)
}

export default AboutOne;